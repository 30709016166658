import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання на The Green Pitch
			</title>
			<meta name={"description"} content={"Відповіді на полі - ваша гра, наші ідеї на The Green Pitch"} />
			<meta property={"og:title"} content={"FAQ | Часті запитання на The Green Pitch"} />
			<meta property={"og:description"} content={"Відповіді на полі - ваша гра, наші ідеї на The Green Pitch"} />
			<meta property={"og:image"} content={"https://ua.pixvos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.pixvos.com/img/946916.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.pixvos.com/img/946916.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.pixvos.com/img/946916.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Часті запитання
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Перш ніж ви зашнуруєте бутси і вирушите до нашого міського футбольного святилища, ось кілька відповідей на запитання, які можуть у вас виникнути щодо відвідування Зеленого поля.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Що таке The Green Pitch?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						The Green Pitch - це найкращий футбольний об'єкт з полем професійного рівня, доступним для гравців та команд усіх рівнів, які можуть насолоджуватися прекрасною грою.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи потрібно приносити власне спорядження?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Хоча особисте спорядження вітається, ми пропонуємо вибір спорядження напрокат, включаючи м'ячі, бутси та захисне спорядження.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Як зарезервувати поле?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ви можете забронювати поле, зв'язавшись з нами безпосередньо по телефону або електронною поштою. Наша команда допоможе вам з вільним часом та тарифами.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи існують вікові обмеження для занять на полі?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми раді бачити гравців будь-якого віку! Ми заохочуємо як юні таланти, так і досвідчених гравців насолоджуватися нашими можливостями.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Яке покриття на полі?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						На нашому полі використовується найсучасніше штучне покриття, розроблене як для продуктивності, так і для безпеки, що забезпечує стабільний ігровий досвід незалежно від погоди.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи можна орендувати поле для проведення заходів?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Так, The Green Pitch можна орендувати для проведення заходів, пов'язаних з футболом, включаючи дні народження, корпоративні заходи та товариські турніри.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи доступні професійні тренери?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми пропонуємо послуги професійного коучингу для окремих осіб та команд. Ці сесії можна замовити окремо, і вони підбираються відповідно до вашого рівня майстерності та цілей.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Що робити, якщо я ніколи не грав раніше?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Зелений майданчик - ідеальне місце для початку! Ми вітаємо новачків у спорті та надаємо всю необхідну підтримку для початку занять.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});